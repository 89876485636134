"use client";

import { useDatadogPageViews } from "@app/hooks/useDatadogPageViews";
import { isTrackingDisabled } from "@app/hooks/useThirdParty";
import { GoogleTagManager } from "@next/third-parties/google";
import dynamic from "next/dynamic";
import { FunctionComponent, createContext, useContext, useState } from "react";
import { useDatadog } from "../../../../lib/hooks/useDatadog";
import env from "../../../../lib/utils/env";
import { i18n } from "../../../../next-i18next.config";

// Polyfills can go here (or in specific components), should be part of main bundle then
import { Toaster } from "@sourceful/design-system-v3";
import "@ungap/global-this";
import { GenerateForm } from "../../components/generate-dialog/forms/formFactory";

const GenerateDialog = dynamic(() => import("../../components/generate-dialog/GenerateDialog"), {
  ssr: false,
});

const GTM_CODE = env("SPRING_GTM_CODE");

export interface PageProviderInjectedProps {
  locale: string;
  setShowGenerateDialog: (show: boolean) => void;
  setGenerateDialogDefaultValues: (values: Partial<GenerateForm>) => void;
}

export interface PageProviderProps {
  children?: React.ReactNode;
}

const PageContext = createContext({} as PageProviderInjectedProps);

const usePageContext = () => useContext(PageContext);

const PageProvider: FunctionComponent<PageProviderProps> = ({ children }) => {
  const [showGenerateDialog, setShowGenerateDialog] = useState(false);
  const [generateDialogDefaultValues, setGenerateDialogDefaultValues] =
    useState<Partial<GenerateForm>>();

  useDatadog();
  useDatadogPageViews();

  return (
    <PageContext.Provider
      value={{
        locale: i18n.defaultLocale,
        setShowGenerateDialog,
        setGenerateDialogDefaultValues,
      }}
    >
      {!isTrackingDisabled() && GTM_CODE && <GoogleTagManager gtmId={GTM_CODE} />}
      {children}

      {showGenerateDialog && (
        <GenerateDialog
          defaultValues={generateDialogDefaultValues}
          onClose={() => setShowGenerateDialog(false)}
        />
      )}

      {/* NOTE: Quick fix - we have issues with nesting now z-indexes come into play.
      So the close button popover doesn't inherit this because its rendered in the body */}
      <div className="relative z-50">
        <Toaster swipeDirection="right" />
      </div>
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider, usePageContext };
